export enum ERouterType {
    PRONTO = 'pronto',
    MERAKI = 'meraki',

}

export type TProntoParams = {
    login_url: string,
    login_secret: string, 
    userurl: string,
    ssid: string,
    nasId: string,
    mac: string
    session_time: string,
}

export type TMerakiParams = {
    user_continue_url?: string,
    continue_url?: string,
    ap_name: string,
    ap_mac: string,
    node_mac: string,
    ap_tags: string,
    client_ip: string,
    client_mac: string,
    base_grant_url: string,
};

export type TCommonParams = {
    sessionTime: string | null,
    loginSecret: string | null,
    loginUrl: string,
    continueUrl: string,
    merchantId?: string,
    merchantLogo?: string,
    clientMac: string,
    routerMac: string,
    routerType: ERouterType,
}