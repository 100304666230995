import React from 'react';
// components
import { Button } from 'spoton-lib';
// local
import { IPropTypes } from './Prompt.types';
import styles from './Prompt.module.scss';

function Prompt(props: IPropTypes): JSX.Element {
    const { title, message, actionText, action } = props;

    function handleActionClick(): void {
        action();
    }

    return (
        <article data-testid={`${title}-prompt`} className={`${styles.Prompt}`}>
            <h3>{title}</h3>

            <p>{message}</p>

            <div className={`${styles.Prompt_buttonGroup}`}>
                <Button
                    onClick={handleActionClick}
                >
                    {actionText || 'Okay'}
                </Button>
            </div>
        </article>
    );
}

export default Prompt;
