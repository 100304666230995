import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Button, Modal, EmblemLogo } from 'spoton-lib';

import styles from './Home.module.scss';
import Prompt from '../Prompt';
import { privacyPolicyElement, termsOfUseElement } from '../legal';
import { ERouterType, TCommonParams } from 'types/common.types';

const Home: FC = () => {
    const [routerError, setRouterError] = useState('');

    const [showTermsOfUse, setShowTermsOfUse] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

    const [goOnline, setGoOnline] = useState(false);
    
    const location = useLocation<TCommonParams>();

    useEffect(() => {
        
        if (!location.state
            || !location.state.routerMac
            || !location.state.continueUrl
            || !location.state.loginUrl
            || (location.state.routerType === ERouterType.PRONTO && !location.state.loginSecret)
        ) {
            setRouterError('There has been an error with your router configuration')
        }
    }, [location.state]);

    if (goOnline) {
        return (
            <Redirect
                to={{
                    pathname: '/login',
                    state: location.state
                }}
            />
        );
    } else if (routerError.length > 0) {
        return (
            <div className={styles.Container}>
                <div className={styles.Error}>{routerError}</div>
            </div>
        )
    } else {
        return (
            <div className={styles.Container}>
                {location.state.merchantLogo && location.state.merchantLogo.length > 0 ?
                    <img
                        className={styles.MerchantLogo}
                        src={location.state.merchantLogo}
                        alt="Merchant Logo"
                    /> : <EmblemLogo />
                }
                <h3 className={styles.Header}>Welcome!</h3>
                <p className={styles.Subtitle}>Enjoy our free WiFi right now</p>
                <Button className={styles.GoOnlineButton} onClick={() => setGoOnline(true)} >
                    Go Online
                </Button>
                <div className={styles.Legal}>
                    By clicking "Go Online", you agree to our&nbsp;
                    <span className={styles.Link} onClick={() => setShowTermsOfUse(true)}>Terms of Use</span>&nbsp;&&nbsp;
                    <span className={styles.Link} onClick={() => setShowPrivacyPolicy(true)}>Privacy Policy</span>
                </div>

                <Modal
                    className={styles.Modal}
                    isOpen={showTermsOfUse}
                    onRequestClose={(): void => setShowTermsOfUse(false)}
                >
                    <Prompt
                        title="Terms Of Use"
                        message={termsOfUseElement}
                        action={(): void => setShowTermsOfUse(false)}
                    />
                </Modal>

                <Modal
                    className={styles.Modal}
                    isOpen={showPrivacyPolicy}
                    onRequestClose={(): void => setShowTermsOfUse(false)}
                >
                    <Prompt
                        title="Privacy Policy"
                        message={privacyPolicyElement}
                        action={(): void => setShowPrivacyPolicy(false)}
                    />
                </Modal>
            </div>
        );
    }
};

export default Home;
