import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import { setAppElement, WordLogo } from 'spoton-lib';

import Splash from 'components/Splash/Splash.component';
import Home from 'components/Home/Home.component';
import Login from './components/Login/Login.component';
import SignUpSuccess from './components/SignUpSuccess/SignUpSuccess.component';
import { ConnectionSuccess } from './components/ConnectionSuccess/ConnectionSuccess.component';

import styles from './App.module.scss';

// Setup for modals
setAppElement('body');

const App: FC = () => {
    return (
        <div data-testid={'app'} className={styles.App}>
            <div className={styles.App_content}>
                <Router>
                    <Switch>
                        <Route exact path='/'>
                            <Redirect to='/splash' />
                        </Route>

                        <Route exact path={'/splash'}>
                            <Splash />
                        </Route>
                        <Route exact path={'/home'}>
                            <Home />
                        </Route>
                        <Route exact path={'/login'}>
                            <Login />
                        </Route>
                        <Route exact path={'/sign-up-success'}>
                            <SignUpSuccess />
                        </Route>
                        <Route exact path={'/connection-success'}>
                            <ConnectionSuccess />
                        </Route>
                    </Switch>
                </Router>
            </div>
            <div className={styles.App_footer}>
                <div className={styles.SpotOnLogo}>
                    <WordLogo />
                </div>
            </div>
        </div>
    );
};

export default App;
