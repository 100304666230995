import React, { FC, SyntheticEvent, useState, ChangeEvent, useEffect } from 'react';
import styles from './Login.module.scss';
import { useLocation } from 'react-router-dom';
import { Redirect } from 'react-router';
import { checkCustomerEmail, createCustomer, getCustomerDeals, joinCustomerAndMerchant } from '../../api/customer.data';
import ECustomerServiceResponse from '../../types/customerServiceResponse.enum';
import { isEmailValid as testIsEmailValid } from 'utils/validationUtils';
import { LoadingOverlay, Button, Input, EmblemLogo } from 'spoton-lib';
import { ERouterType, TCommonParams } from 'types/common.types';

const Login: FC = () => {
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);

    const [outputError, setOutputError] = useState<string>();
    const [navigateToSuccess, setNavigateToSuccess] = useState(false);
    const [navigateToGuestSuccess, setNavigateToGuestSuccess] = useState(false);

    const [isLoading, setLoading] = useState(false);

    const location = useLocation<TCommonParams>();

    useEffect(() => {
        
        if (!location.state
            || !location.state.routerMac
            || !location.state.continueUrl
            || !location.state.loginUrl
            || (location.state.routerType === ERouterType.PRONTO && !location.state.loginSecret)
            || !location.state.merchantId
        ) {
            setOutputError('There has been an error with your router configuration')
        }
    }, [location.state]);

    function checkEmail(newEmail: string): void {
        const empty = newEmail.length === 0;
        setIsEmailValid(!empty && testIsEmailValid(newEmail));
        setEmail(newEmail.trim());
    }

    function onInputChange(e: ChangeEvent<HTMLInputElement>): void {
        e.preventDefault();
        const newValue = e.target.value;
        checkEmail(newValue);
    }

    const createCustomerAndJoin = async () => {
        try {
            // New customer detected: create new customer, and associate with merchant
            setLoading(true);
            const createResponse = await createCustomer(email);

            let newCustomerId = createResponse.mongo_id;

            // Associate merchant with newly created customer
            // We can assume that the merchantId is defined, as it is checked in the useEffect
            await joinCustomerAndMerchant(newCustomerId, location.state.merchantId!);

            // After successful join, let customer through
            setNavigateToSuccess(true);
        } catch (e) {
            if ((e as any)?.error_code) {
                setOutputError(`Error Creating/Joining Customer`);
            } else {
                setOutputError(`Error Creating/Joining Customer`);
            }

            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const onConnectClick = (e: SyntheticEvent) => {
        e.preventDefault();

        // TODO: Implement e-mail verification via SendGrid
        const signCustomerIn = async () => {
            setOutputError('');
            try {
                setLoading(true);
                const checkResponse = await checkCustomerEmail(email);
                let customerId = checkResponse.customerId;

                const customerMerchantResponse = await getCustomerDeals(customerId);

                if (customerMerchantResponse.merchants) {
                    const targetMerchant = customerMerchantResponse.merchants.find((cm: any) => cm.cm_merchant_mongo_id === location.state.merchantId);

                    // If customer has no associated merchants, or is not associated with the current merchant, then join the customer and merchant
                    if (customerMerchantResponse.merchants.length === 0 || !targetMerchant) {
                        // We can assume that the merchantId is defined, as it is checked in the useEffect
                        await joinCustomerAndMerchant(customerId, location.state.merchantId!);

                        setNavigateToSuccess(true);
                    } else {
                        setNavigateToSuccess(true);
                    }
                }
            } catch (e) {
                if ((e as any)?.error_code === ECustomerServiceResponse.CustomerNotFound) {
                    await createCustomerAndJoin();
                } else if ((e as any)?.error_code) {
                    setOutputError(`Error Joining Customer with Merchant: ${location.state.merchantId}`);
                } else {
                    setOutputError(`Error Signing Customer In`);
                }
            } finally {
                setLoading(false);
            }
        };

        signCustomerIn();
    };

    const onContinueAsGuestClick = () => {
        setNavigateToGuestSuccess(true);
    };

    const renderMerchantLogo = () => {
        if ( location.state.merchantLogo && location.state.merchantLogo.length > 0) {
            return (
                <img className={styles.MerchantLogo} src={location.state.merchantLogo} alt="merchant-logo" />
            );
        }

        return (
            <EmblemLogo />
        );
    }

    if (navigateToSuccess) {
        return (<Redirect
            to={{
                pathname: '/sign-up-success',
                state: location.state
            }}
        />);
    } else if (navigateToGuestSuccess) {
        return (<Redirect to={{
            pathname: '/connection-success',
            state: location.state
        }}
        />);
    } else {
        return (
            <div className={styles.Container}>
                {location.state && renderMerchantLogo()}
                {isLoading && (
                    <div className={styles.DivSpinner}>
                        <LoadingOverlay hasBackgroundBlur/>
                    </div>
                )}

                <h3 className={styles.Header}>Sign Up!</h3>

                <p className={styles.Subtitle}>Your information is safe with us.</p>

                <p className={styles.OfferSubtitle}>We will send you VIP offers.</p>
                <form className={styles.Form} onSubmit={onConnectClick}>

                    <label className={styles.EmailInput_label}>Email</label>

                    <Input
                        type="email"
                        name={'input'}
                        maxLength={255}
                        className={styles.EmailInput}
                        value={email}
                        isValid={isEmailValid}
                        onChange={onInputChange}
                        errorMessage="Please enter a valid e-mail"
                        clearable
                        required
                    />

                    <Button
                        type="submit"
                        disabled={!isEmailValid || email.length === 0}
                        className={styles.SignUpButton}
                        onClick={() => {}}
                    >
                        Sign Up & Connect
                    </Button>

                    <Button
                        variant="tertiary"
                        className={styles.ContinueGuestButton}
                        onClick={onContinueAsGuestClick}
                    >
                       <span className={styles.ContinueGuestButton_text}>Continue as Guest</span>
                    </Button>

                    {
                        outputError && outputError.length > 0 &&
                        <div className={styles.Error}>{outputError}</div>
                    }

                </form>

            </div>
        );
    }
};

export default Login;
