import { getMerchantDetailsByMacAddress } from "api/merchant.data";
import {
  ERouterType,
  TCommonParams,
  TMerakiParams,
  TProntoParams,
} from "types/common.types";

// Type guard for StandardizedParams
function isCommonParams(params: any): params is TCommonParams {
  return "routerType" in params && "routerMac" in params;
}

class RouterHandler {
  private standardizedParams: TCommonParams;
  private routerType: ERouterType;

  constructor(params: TMerakiParams | TProntoParams | TCommonParams) {
    if (!isCommonParams(params)) {
      this.routerType =
        "nasId" in params ? ERouterType.PRONTO : ERouterType.MERAKI;
      this.standardizedParams = this.mapParams(params);
    } else {
      this.standardizedParams = params as TCommonParams;
      this.routerType = params.routerType;
    }
  }

  private mapParams(params: TProntoParams | TMerakiParams): TCommonParams {
    if ("nasId" in params) {
      // Pronto params
      return {
        loginSecret: params.login_secret,
        loginUrl: params.login_url,
        continueUrl:
          params.userurl === "(null)" ? "spoton.com" : params.userurl,
        merchantId: undefined,
        merchantLogo: undefined,
        clientMac: params.mac,
        routerMac: params.nasId,
        routerType: this.routerType,
        sessionTime: params.session_time ?? 3600,
      };
    } else {
      // Meraki params
      return {
        loginSecret: null,
        loginUrl: params.base_grant_url,
        continueUrl:
          params.continue_url ?? params.user_continue_url ?? "spoton.com",
        merchantId: undefined,
        merchantLogo: undefined,
        clientMac: params.client_mac,
        routerMac: params.ap_mac ?? params.node_mac,
        routerType: this.routerType,
        sessionTime: null,
      };
    }
  }

  validateParams() {
    if (!this.params.routerMac) {
      throw new Error("Missing router MAC address");
    }

    if (!this.params.loginUrl) {
      throw new Error("Missing login URL");
    }

    if (this.routerType === ERouterType.PRONTO && !this.params.loginSecret) {
      throw new Error("Missing login secret");
    }
  }

  async fetchAndSetMerchantInfo(): Promise<void> {
    if (!this.params.routerMac) {
      throw new Error("Missing router MAC address");
    }

    try {
      const merchantInfo = await getMerchantDetailsByMacAddress(
        this.params.routerMac
      );
      this.params.merchantId = merchantInfo.merchantId;
      this.params.merchantLogo = merchantInfo.logoUrl;
    } catch (e) {
      console.error("Error getting merchant info: ", e);
      throw new Error(
        `Could not find merchant given mac address: ${this.params.routerMac}`
      );
    }
  }

  async authenticate() {
    if (!this.params.loginUrl) {
      throw new Error("Missing login URL");
    }

    if (this.routerType === "pronto") {
      window.location.href = `${this.params.loginUrl}?login_secret=${this.params.loginSecret}&mac=${this.params.clientMac}&userurl=${this.params.continueUrl}&session_time=${this.params.sessionTime}`;
    } else {
      // Meraki
      window.location.href = `${this.params.loginUrl}?continue_url=${this.params.continueUrl}`;
    }
  }

  updateParams(newParams: Partial<TCommonParams>) {
    this.standardizedParams = { ...this.standardizedParams, ...newParams };
  }

  get params(): TCommonParams {
    return this.standardizedParams;
  }
}

export default RouterHandler;
