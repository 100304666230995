import axios from 'axios';
import IResMerchantInfoByMacAddress from '../types/resMerchantInfoByMacAddress.interface';

const apiUri = process.env.NODE_ENV === 'production'
    ? (window as any)._env_.REACT_CAPTIVE_PORTAL_API
    : process.env.REACT_APP_CAPTIVE_PORTAL_API;

export const getMerchantDetailsByMacAddress = (macAddress: string): Promise<IResMerchantInfoByMacAddress> => {
    const safeMacAddress = macAddress;
    safeMacAddress.replace(/<|>|(%3c)|(%3e)/gi, '');

    return axios.get(
        `${apiUri}merchant/${safeMacAddress}`
    ).then(res => {
        return Promise.resolve(res.data)
    }).catch(err => {
        return Promise.reject(err)
    })
};
