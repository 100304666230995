import React, { FC, useEffect, useState } from 'react';
import { Redirect, RouteProps } from 'react-router';
import { useLocation } from 'react-router-dom';
import * as queryString from 'querystring';
import { ParsedUrlQuery } from 'querystring';
import styles from '../Home/Home.module.scss';
import RouterHandler from 'utils/routerHandler/routerHandler';
import { TMerakiParams, TProntoParams } from 'types/common.types';

const Splash: FC<RouteProps> = () => {
    const [queryParams, setQueryParams] = useState<ParsedUrlQuery>();
    const [routerError, setRouterError] = useState('');
    const [routerHandler, setRouterHandler] = useState<RouterHandler | null>(null);
    const [isLoading, setIsLoading] = useState(true); 
    const location = useLocation();

    useEffect(() => {
        const queryStringValues = queryString.parse(location.search.slice(1)) as  TMerakiParams | TProntoParams;

        setQueryParams(queryStringValues);
        // Initialize RouterHandler and fetch merchant info
        // we are omitting decoding login_secret because pronto devices expect the same value as they pass in the url
        const handler = new RouterHandler({ ...queryStringValues, login_secret: location.search?.match(/[?&]login_secret=([^&]*)/)?.[1] });
        try {
            handler.validateParams();
        } catch (e:any) {   
            setIsLoading(false); 
            setRouterError(e.message);
            return;
        }
        setRouterHandler(handler);
        handler.fetchAndSetMerchantInfo().catch(e => {
            setRouterError(e.message);
        }).finally(() => {
            setIsLoading(false); 
        });
    }, [location.search]);

    if (routerError.length > 0) {
        return (
            <div className={styles.Container}>
                <div className={styles.Error}>{routerError}</div>
            </div>
        )
    } else if (queryParams && routerHandler && !isLoading) {
        const params = routerHandler.params; // Get the standardized parameters from RouterHandler

        return (
            <Redirect to={{
                pathname: '/home',
                state: params
            }}/>
        );
    } else {
        return (
            <div>Parsing...</div>
        );
    }
};

export default Splash;
