import React from 'react';

import styles from './legal.module.scss';

export const termsOfUseElement = (
    <div className={styles.TermsOfUse}>
        <b>SpotOn User Terms of Service</b>
        <p>Welcome to SpotOn!</p>
        <b>What SpotOn Does</b>
        <p className={styles.TermsOfUse_text}>SpotOn Transact, LLC (together with its affiliates, “SpotOn”) provides various technology (the “SpotOn Technology”) and services to merchants. Such merchant services allow participating merchants to provide services to you, which may include sending you special offers; offering loyalty programs; or allowing you to schedule appointments, make reservations, purchase goods or services from the merchant, and/or to pay the merchant through SpotOn’s payment processing services (these and other services provided by SpotOn to merchants are called “Merchant Services”).
            SpotOn may also provide services directly to you, through a SpotOn website (a “SpotOn Website”) and/or one or more mobile apps (“SpotOn Mobile”), to allow you to interact through SpotOn with merchants that use SpotOn Technology.  Such services may allow you to sign up through SpotOn to participate in a merchant’s loyalty program or to receive messages and special offers from a merchant; make appointments or reservations, or purchase goods and services from a merchant  through SpotOn (these and other services that SpotOn provides directly to you and other persons are called “User Services,” and all users of User Services are called “Users.”).
            We call each merchant that uses SpotOn Technology a “Merchant” and we call the Merchant Services and the User Services, together, the “Services.”
            These Terms of Service and SpotOn’s Privacy Policy apply to the User Services, whether performed by SpotOn Transact, LLC, one of its affiliates, or a third party on behalf of SpotOn Transact, LLC or any of its affiliates.  When you interact with Merchants using SpotOn Technology, the Merchant’s terms of service and privacy policy apply; you should read and accept each Merchant’s terms of service and privacy policy before interacting with such Merchant.
            Each Merchant has agreed with SpotOn that it will follow certain rules in its interactions with Users through the SpotOn Technology; those agreed-upon rules are summarized below, under the title “The Roles of Merchants and SpotOn; Merchant Rules.”  While SpotOn will provide the SpotOn Technology only to Merchants that have agreed to those rules, SpotOn is not responsible for assuring that each Merchant acts in accordance with them.  The rules are summarized in these Terms of Service only so that you will be aware of SpotOn’s expectations with respect to how Merchants interact with you.  You should check with each Merchant to confirm that they are following those rules and that you agree to each Merchant’s terms of use and privacy policy.  Because a Merchant’s terms of use and privacy policy may change over time, you should check them frequently.</p>
    </div>
);

export const privacyPolicyElement = (
    <div className={styles.PrivacyPolicy}>
        <b>SpotOn Privacy Policy for Consumers</b>

                                     <p className={styles.PrivacyPolicy_text}>SpotOn Transact, LLC (“SpotOn”) provides certain services directly to individual users like you (“Consumers”), and also provides technology for use by merchants (together, the “Services”).  Merchants that are customers of SpotOn (“Merchants”) may provide certain information about you to SpotOn, and SpotOn may provide certain information about you to Merchants.
    This Privacy Policy covers only how SpotOn treats information relating to you, whether that information comes from your direct interaction with SpotOn or comes from Merchants with which you interact.  Each Merchant has agreed with SpotOn to treat information relating to Consumers that it obtains from SpotOn or as a result of the Merchant’s use of SpotOn technology and Services as described in the Merchant Rules, which are available here.  SpotOn has no responsibility for assuring that Merchants comply with the Merchant Rules, and you should consult each Merchant’s Privacy Policy to determine how that Merchant uses information relating to you.
    SpotOn respects the personal privacy of Consumers. This Privacy Policy describes how SpotOn uses the information of people who visit a SpotOn website (a “SpotOn Website”), use one or more of SpotOn’s mobile apps (“SpotOn Mobile”), or interact with  SpotOn’s technology used at Merchants’ sites (such as tablets and point-of-sale devices).  This policy also applies to information submitted or uploaded to SpotOn by a Consumer (e.g., through Facebook, other social media sites or otherwise) or uploaded to SpotOn by a Merchant (each social media site or Merchant, a “SpotOn Access Portal”), and to information gathered by SpotOn as Consumers use any of the Services.
    This Privacy Policy does not apply to any use of your information by a Merchant, to any other services or websites that may be offered by a Merchant, or to any third-party sites that may be accessible from a SpotOn Access Portal or through the Services provided by SpotOn. Further, this Privacy Policy does not apply to Facebook1, Twitter, or any other third-party sites through which any SpotOn Services may be delivered. To understand how any third party treats your information (including your information related to the use of SpotOn Services), you should contact such third party or read its privacy policy. We encourage you to read this entire Privacy Policy before using a SpotOn Website, the SpotOn Mobile, a SpotOn device located at a Merchant, or submitting or uploading information through any Merchant or any other SpotOn Access Portal.
    Do not use a SpotOn Website, SpotOn Mobile, a SpotOn device located at a Merchant, or any other SpotOn Access Portal if you are unwilling to accept the terms and conditions of this Privacy Policy. By using SpotOn Access Portals or the Services, you agree to the terms of this Privacy Policy. When you submit information to SpotOn or use SpotOn’s services, you consent to the collection, storage, use, and disclosure of that information in accordance with this Privacy Policy.
    After reading this Privacy Policy, which is incorporated into and is a part of the User Terms of Service (“User Terms”), you will know:

    What information SpotOn collects from you;
How SpotOn uses and may disclose information it obtains through a SpotOn Website, SpotOn Mobile or the Services;
What choices you have about how SpotOn treats your information;
How SpotOn protects your privacy;
What SpotOn does about children visiting a SpotOn Website or Spoton Mobile;
How comments you may make via SpotOn are handled; and
How you can contact SpotOn
INFORMATION SPOTON COLLECTS FROM YOU
Active Information Collection
SpotOn actively collects information from our visitors to a SpotOn Website or SpotOn Mobile both by asking specific questions, by allowing you to upload information through other tools (such as Facebook Connect or other social media site), and by permitting you to communicate directly with us through a SpotOn Website, SpotOn Mobile, via email or otherwise. If you sign up with SpotOn to use the Services directly with SpotOn or through any SpotOn Access Portal (including a Merchant or social media site), or if a Merchant signs you up for its services that use SpotOn technology, some of the information that you submit (directly or via an upload), or that is submitted for you by a Merchant, is required to register you to use the Services. Some of such information could identify you personally, such as your first and/or last name, telephone number, email address, zip code and birthdate. Such information is called “Personally Identifiable Information.” Some Services or programs may require you (or a Merchant on your behalf) to enter your demographic information and/or additional Personally Identifiable Information, such as your name (if not previously provided), gender, financial / credit card information or other information.
    SpotOn and Merchants may offer you, directly or through Facebook, Twitter, other social media sites, or other third parties, the opportunity to participate in activities related to the Services, such as responding to surveys, entering a contest or sweepstakes, playing games or providing information to our customer service representatives. If you choose to participate in such activities (when offered) SpotOn or Clients may collect additional Personally Identifiable Information or other information from you with your consent.
    When you visit a SpotOn Website, SpotOn Mobile, use the Services, or participate in activities that are part of the Services, SpotOn and Merchants may collect information related to such activities. SpotOn does not intentionally collect Personally Identifiable Information about you other than (i) such information that you provide us as described above, either directly to SpotOn or through a SpotOn Access Portal (including through permissions you authorize with social media sites which are used in conjunction with the Services (e.g., Facebook, Twitter and other sites)), or (ii) information submitted to SpotOn for you by one or more Merchants. SpotOn may, however, keep track of your responses to Offers (defined below), visits to and purchases from Merchants and associate that information with your registration information, including your Personally Identifiable Information (see “Program Information Collection,” below).
Passive Information Collection
As you navigate through a SpotOn Website, SpotOn Mobile or use the Services, SpotOn may collect certain information passively (that is, information may be gathered without you actively providing the information) using various technologies and means, such as navigational data collection and geolocation data. The foregoing data may also be collected when you interact with the SpotOn technology, for example, by making an appointment or reservation or making a payment.  Like most website operators, SpotOn, its advertisers and third-party service providers may track IP addresses, use both session and persistent cookies, and assign Internet tags. SpotOn also automatically gathers information about the areas you visit on a SpotOn Website and SpotOn Mobile and collects operational information about the technology you use, such as your browser, type of computer or mobile device, operating systems, Internet service provider and the domain names of the websites from which you linked to the SpotOn website or SpotOn Mobile and to which you go after viewing such sites. If you activate geolocation applications, SpotOn may have access to and store your geolocation information related to the Services.
    In addition, when you visit a SpotOn Website or SpotOn Mobile, SpotOn may store some information on your computer or mobile device. This information will be in the form of a cookie or similar file and will help us in many ways. For example, cookies allow us to tailor our websites, our mobile apps and the Services or other offerings to you in a way that better matches your interests and preferences. Most Internet browsers enable you to erase cookies from your computer hard drive, block all cookies, or receive a warning before a cookie is stored. Please refer to your browser instructions or help screen to learn more about these functions. SpotOn does not collect Personally Identifiable Information automatically, but we may tie information collected automatically to your previously submitted Personally Identifiable Information.
    A SpotOn Website, SpotOn Mobile, or any other SpotOn interface that connects you to the Services may contain electronic images known as web beacons—also referred to as single-pixel gifs—that permit SpotOn to count users who have visited the website or mobile app and for other related statistics—(e.g., recording the popularity of certain content and verifying system and server integrity). Web beacons are not used by SpotOn to access your Personally Identifiable Information; they are used solely to compile aggregated statistics concerning the use of a SpotOn Website or SpotOn Mobile.  Web beacons collect only a limited set of information including a cookie number, the time and date of a page view, and a description of the page on which the web beacon resides.
    The reason SpotOn collects passive information is that it helps us understand which areas of a SpotOn Website and SpotOn Mobile are most popular, which areas may require improvement and what technologies are being used by our visitors. This information helps SpotOn to update and improve its websites and mobile apps, and our Services. SpotOn also uses this information for security purposes, to detect and to block security breaches and to provide you with a safer online environment.
    Program Information Collection
After you register with SpotOn (directly or through a SpotOn Access Portal) or are registered by a Merchant, you may receive “Offers,” defined as special promotions or offers from one or more Merchants; may have such Offers sent to you automatically via email, mobile communications (e.g., SMS text messaging, mobile notifications), Facebook, Twitter or other social media; may be able to post those Offers on your own Facebook page to share with your friends; and/or may be able to use other tools offered by SpotOn. SpotOn may automatically keep track of all of those choices and associate them with the registration information you provided or that was provided for you by a Merchant, including your Personally Identifiable Information you (or a Merchant on your behalf) may have provided. SpotOn may also keep track of, and associate with your Personally Identifiable Information, activities you engage in offline that relate to the Services, such as which Offers you respond to (for example, by purchasing the goods or services that are the subject of the Offer) and loyalty rewards you earn from offline purchases, your reservations and appointments, your spending habits, and other services that SpotOn may make available to you.  All of the information described in this paragraph is called “Merchant Participation Data.” SpotOn may use Merchant Participation Data for a number of purposes, including keeping track of rewards points (if offered by the Merchant), categorizing you with respect to your response to Offers and rewards programs of each Merchant, and marketing goods or services of Merchants and SpotOn as described above. SpotOn will offer you the option not to receive such marketing messages from SpotOn.
    If SpotOn permits certain Merchants with which (or by which) you are registered to do so, such Merchants may also keep track of Merchant Participation Data and use it as described above, including marketing their goods or services as described above. If a Merchant is given permission to use its Merchant Participation Data, SpotOn will require such Merchant to agree to offer you an option not to receive such marketing messages.
    In addition to the foregoing, SpotOn will have access to information regarding your registration with and participation in the Offers and loyalty programs offered by all Merchants for which, or by which, you are registered (“Program Participation Data”). Program Participation Data may be used by SpotOn to keep track of loyalty points that you have accrued with each Merchant SpotOn works with, which may allow you to check all of those programs you are enrolled in on one page of a SpotOn Website or SpotOn Mobile.  SpotOn may also use Program Participation Data, combined with your Personally Identifiable Information, for purposes such as categorizing you with respect to various Merchants or types of Merchants, Offers and loyalty programs and for sending you marketing messages for goods or services of SpotOn or other Merchants or service providers.  Program Participation Data may enable SpotOn to market third-party goods and services to you, as described above. SpotOn will offer you the option not to receive such marketing messages. To do so, you should use the link provided by SpotOn in the message to elect not to receive future marketing messages, or if you are using SpotOn Mobile, you can adjust the application’s notification settings.
    Third Party Information Collection
Currently, the SpotOn technology operates on SpotOn Websites, SpotOn Mobile, SpotOn devices at Merchants’ locations, may operate on Merchant’s websites, any Spoton.com URLs, and also operates on Facebook’s platform and other social media platforms.  In the future, the SpotOn technology could operate on other social media or other locations. Depending on the choices you make when you register (or a Merchant registers for you), Offers may be sent to you through Facebook, Twitter or other social media, and additional social media applications may in the future be included with our services (e.g., geolocation information). Information related to you, your use of the SpotOn technology and the goods and services provided by SpotOn and Clients may be available to Facebook, Twitter and other third parties, such as the operators of various social media or other sites.
    The privacy policies of Merchants, social media and other sites, and sites that you may link to from sites that employ the SpotOn technology, are not under the control of SpotOn.  SpotOn does not have any responsibility or liability for the use of your personal information by Merchants, Facebook, Twitter, other social media companies or any other third parties. SpotOn is also not responsible for the collection, storage or use of your information by any such third parties, for their privacy policies or for their security practices. If you would like information on any other party’s privacy policy, you should contact that party directly, and we encourage you to do so.
    This Privacy Policy is applicable only to information you submit to SpotOn (directly or through uploads authorized by you), that is submitted to SpotOn for you by a Merchant, or that SpotOn collects from the SpotOn technology or the Services. If you link from a SpotOn Website, SpotOn Mobile, any site or device operating the SpotOn technology, or the website of a Merchant that operates on SpotOn technology to the site or mobile app of any third party, your activities on those sites are subject to the separate privacy policies of the respective third parties (including Merchants) applicable to those particular sites or mobile apps. If a Merchant or other third party maintains a website or mobile app without a privacy policy, you should ask such Merchant or other third party about its privacy policies.
    SpotOn may hire third parties to assist our marketing efforts by placing SpotOn ads on other websites or search engines. We may also use third parties to display ads of unrelated companies on a SpotOn Website, SpotOn Mobile or any other SpotOn Access Portal. Such third party marketers or advertisers may use cookies to serve ads based on your prior visits to theSpotOn Website, SpotOn Mobile or other SpotOn Access Portal, and other sites or apps you have visited.. SpotOn does not have any control over such cookies. You may be able to modify your browser settings to decline such cookies. If you decline cookies that affect your use of the SpotOn Website some features may not be available or may not operate properly. For example, if you access the SpotOn Services through Facebook, ads you may see framing the Services or presented in association with them are controlled by Facebook. You should review the Facebook Privacy Policy and/or contact Facebook for information regarding such advertising, including whether any such advertisements may solicit, or contain technology to collect, information about you.
    HOW SPOTON MAY USE AND DISCLOSE INFORMATION OBTAINED THROUGH THE SERVICES
Internal Use of Personally Identifiable Information.
    Except as otherwise stated, SpotOn may use information collected via any SpotOn Access Portal (including a SpotOn Website or SpotOn Mobile) or via the SpotOn technology to improve the content of any SpotOn Website, SpotOn Mobile, the Services (or similar or related services) to your preferences; to communicate information to you if you have requested it (such as your accrued rewards points); for our marketing and research purposes (including marketing other services provided by SpotOn, Merchants or third parties), and for any other purpose specified in this Privacy Policy. You can opt out of receiving such marketing messages through the “Settings” tab in the SpotOn App and by choosing the opt-out option provided in email messages.
    You provide Personally Identifiable Information when you register for the Services through any SpotOn Access Portal, including a SpotOn Website or SpotOn Mobile (for example, on the registration page or through an upload you authorize), and a Merchant may send SpotOn such information for you. SpotOn may combine any such information with Client Participation Data and/or Program Participation Data, as described in the section entitled “Program Information Collection.”
            SpotOn may provide Personally Identifiable Information you have provided to SpotOn (directly or through an upload), or that has been submitted to SpotOn by a Merchant on your behalf, to certain Clients with which (or by which) you are registered, and that have been permitted such information access by SpotOn. When and if permitted by SpotOn, such Merchants may use that information to send you messages, independently of the Services, about their goods and services and about the goods and services of other merchants. If you do not wish to receive such messages you may opt out of receiving them by choosing the opt-out option provided in such messages. All Merchants granted such permission by SpotOn will have agreed not to provide your Personally Identifiable Information to third parties except as described in the next section.  Merchants have also agreed to abide by the rules and obligations under the CAN-SPAM Act of 2003 (15 U.S.C.  § 103, et seq).  SpotOn is not responsible for assuring that Merchants comply with these commitments.
    Limited Disclosure of Personally Identifiable Information to Third Parties.
    SpotOn will not disclose Personally Identifiable Information provided by you or by Merchants on your behalf, or that SpotOn collects in conjunction with the Services, to other third parties (i.e., to parties other than Merchants with which (or by which) an end user is registered) without consent, except as described below. Although SpotOn is not responsible for assuring that Merchants comply with their commitments, it does require each Merchant to agree to limit disclosure of Consumers’ Personally Identifiable Information to the following:
    (A) to an acquirer in the event of a sale or merger of Merchant’s business, provided that the acquirer agrees to maintain and use such data regarding Consumers in accordance with this Privacy Policy and the User Terms,
    (B) as necessary to fulfill an order for a Consumer,
    (C) to service providers of Merchant who need to know such information to provide services to Merchant consistent with their obligations and such service providers are obligated to keep such information confidential,
    (D) if required by law or subpoena, in response to an inquiry from law enforcement authorities or regulators, or
(E) if Merchant believes the release of such information is necessary to address or prevent illegal or harmful activity.
    Other than as described above, SpotOn does not distribute Personally Identifiable Information to third parties, and Merchants have agreed not to do so either.  SpotOn is not responsible for assuring that Merchants comply with that commitment.
    You understand that if you access the Services through Facebook or any other third party platforms, Facebook or the operator of such platforms will have access to your registration information and other information generated by your use of the Services, which may be aggregated with other information about you accessible to Facebook or the operator of such platforms, and may be stored and used by them, subject to the applicable privacy policy.
    SpotOn will not have any liability for disclosure of your information resulting from actions by Facebook or other social media companies or platforms, or from errors in transmission or the unauthorized intervention of third parties.
    Information that Does Not Identify Your Business or You Personally.
    SpotOn may provide aggregate statistics or information about its customers, sales, trends, loyalty program usage, online traffic patterns, related information or other statistical de-identified or aggregated information to third parties, but these statistics or information will not include any  Personally Identifiable Information (“Non-Identifying Information”). Non-Identifying Information is also used by SpotOn and/or third parties we contract with to troubleshoot, operate and improve SpotOn Websites, SpotOn Mobile and SpotOn technology, to assist us with marketing or ad placement, to cooperate with law enforcement activities or legal orders, to protect our rights (including enforcing the Merchant Terms and User Terms) or to address or prevent illegal or harmful activity. In addition, SpotOn may use all information submitted by Consumers that SpotOn collects about them that is Non-Identifying Information in any way SpotOn deems useful, including publishing statistics such as those described above to promote SpotOn or its Services.
    Remarketing
SpotOn may use information that Consumers provide or that SpotOn collects about Consumers from their use of any SpotOn Website, SpotOn Mobile, the Services or resulting from use of the SpotOn technology, including their response to Offers from Merchants, their participation in the rewards programs of Merchants (if any) and/or other information, to send Consumers marketing messages related to goods or services SpotOn believes will be of interest to them. Such goods or services may be offered by SpotOn, Merchants, or other third parties. (See description above, under “Program Information Collection.”). Such marketing messages may be sent via email, mobile communications, Facebook, Twitter, other social media or otherwise, depending on how the Consumer has elected to receive Notes from various Merchants.  Consumers can opt out of receiving such marketing messages through the “Settings” tab in the SpotOn App and by choosing the opt-out option provided in email messages.
    CHOICES END USERS HAVE ABOUT HOW SPOTON USES THEIR INFORMATION
SpotOn strives to provide Consumers with choices regarding the information they provide. SpotOn has created mechanisms to provide Consumers with the following control over their information:
    (i) You may view and modify the Personally Identifiable Information and/or Optional Information you submitted through a SpotOn Access Portal, or that was submitted to SpotOn on your behalf by a Merchant, as follows:  you may modify the information you submitted when you registered, or when a Merchant registered you, by going to the applicable SpotOn Website or SpotOn Mobile.  You may opt out of messages from SpotOn or revoke your consent to such messages, by turning off notifications for SpotOn Mobile or by choosing the opt-out option provided in any messages.  If you want to stop receiving all messages through the Services, you will need to delete the SpotOn App from your device, visit each social media company through which the you chose to have the Services provided (e.g., Facebook, Twitter, etc.) and delete SpotOn from each such social media company, and choose the opt-out option provided in messages sent via email. Each Merchant has agreed to allow Consumers to stop receiving communications from it by allowing Consumers to opt out of such messages or to alter or revoke consents to communications from the Merchant.  SpotOn is not responsible for assuring that Merchants comply with that commitment.
    SpotOn keeps track of Consumers’ transactions with Merchants or with services provided by SpotOn technology, as well as communications about Merchants that Consumers makes through SpotOn technology (e.g., posting Offers to a Facebook page, sending Offers to friends). Consumers cannot delete information associated with such transactions and communications.
(ii) Marketing messages from SpotOn will contain an option for the Consumer to choose not to receive such messages in the future. When and if marketing messages from Merchants or other or third parties are permitted, SpotOn intends to require the Merchant or other third party to agree to allow Consumers to choose not to receive such messages.  SpotOn will not be responsible for assuring that Merchants or other third parties comply with such commitments.
(iii) If a Consumer does not wish SpotOn to collect cookies, he or she may set the browser to refuse cookies, or to alert the user when cookies are being sent. Please note that some parts of the SpotOn Services may then be inaccessible or may not function.
HOW SPOTON PROTECTS YOUR INFORMATION
SpotOn works hard to protect the personal information of Consumers, using both technical and procedural methods to maintain the integrity and security of our databases. These include the use of SSL encryption, firewalls, administrative access level controls and employee training. Consumers should keep in mind, however, that no Internet transmission or data storage is ever completely secure or error-free. While SpotOn believes it uses commercially reasonable security procedures, it cannot guarantee the security of any information submitted by Consumers or information gathered about Consumers or information stored by SpotOn.
    Each Merchant has agreed to abide by this Privacy Policy. SpotOn is not responsible for Merchants’ compliance with this Privacy Policy or for Merchants’ security policies and practices. You acknowledge and agree that SpotOn has no responsibility or liability for the security policies or practices of any Merchant, for any security breach of any Merchant’s systems or databases, or for Merchants’ compliance with assurances made by the Merchant to SpotOn or to Consumers. SpotOn is not responsible for the actions of any third party, including but not limited to any Merchant, Facebook, other social media sites or other sites, with whom information about you may be shared or who may otherwise obtain such information.
    The safety and security of information of each Consumer also depends on the Consumer.  You should never share your SpotOn passwords, or any other password (including passwords you use with Merchants, Facebook or any other social media site), with anyone else, and should notify SpotOn, or any other relevant provider site if you believe your password security has been breached. Remember to log off of each SpotOn Website and SpotOn Mobile before you leave your computer or mobile device.
    The SpotOn Websites, SpotOn Mobile or other sites through which you may use the Services may contain links to other sites and/or resources over which neither SpotOn nor Merchants have any control. SpotOn makes no representations or warranties with respect to the content, ownership, or legality of any such linked sites. Links to third-party sites are provided solely as a convenience to you and should not be construed as an affiliation with or endorsement by SpotOn or any Merchant of such third parties or any content, items, or services on third-party sites. Access and use of such other sites, including the content, items, or services on those sites, solely at the risk of the user, and  SpotOn has no responsibility or liability resulting from your access or use of such other sites. These linked sites may collect personal information from you when you enter their site. Any such collecting of information is not subject to the control of SpotOn. To ensure protection of privacy, you should always review the privacy policies of the sites you visit when linking from a SpotOn Website, SpotOn Mobile or any other site associated with the Services.
    You acknowledge and agree that SpotOn has no responsibility or liability for the availability of such external sites or resources, or for the content, advertising, products, or other materials available through such sites or resources.
    WHAT SPOTON DOES ABOUT CHILDREN VISITING SPOTON
The SpotOn Website, SpotOn Mobile and the Services are not intended for children under the age of 13 and are not designed to attract anyone under the age of 13.  Merchants who use SpotOn’s Services may sell goods or services for children that are intended to be purchased by adults. SpotOn will not knowingly collect Personally Identifiable Information from site visitors under the age of 13, and Merchants have agreed that they will not knowingly collect such information. SpotOn is not responsible for assuring that Merchants comply with that commitment.
    SpotOn encourages parents to talk to their children, including children between the ages of 12 and 18, about their use of the Internet and the information they disclose online. If a child of any age has provided SpotOn with Personally Identifiable Information, and a parent or guardian of that child would like this information deleted from SpotOn’s records, such parent or guardian may contact SpotOn by going to the applicable SpotOn Website and clicking on the appropriate tab.  SpotOn will then use reasonable efforts to delete the child’s information from our databases.
    HOW PUBLIC COMMENTS ARE HANDLED
Consumers may be allowed to post public comments, including comments about Merchants and their goods and services, on a Merchant’s Facebook page or on other social media. Such capability is offered by Facebook or another social media company, and is subject to the privacy policy of Facebook or such other social media company. We encourage you to review such privacy policies before submitting any such comments. Consumers may also be allowed to post similar comments on a SpotOn Website or SpotOn Mobile. Any messages posted in public access portions of Facebook, other social media sites a SpotOn Website or SpotOn Mobile may be read, saved, used and/or distributed by such social media companies, SpotOn, Merchants and any other users of the sites. Any such postings may be re-posted by any of such users on other websites, used in blogs, etc., and may appear in web searches. SpotOn cannot control who views information so posted or what they do with it, so care should be used when sending any personal information to any public access site. SpotOn also cannot control and is not responsible for the accuracy, truthfulness or tastefulness of any information posted by other users on Facebook or other social media sites, on a SpotOn Website, SpotOn Mobile or on any other public access site.
    CONTACTING SPOTON
Questions, comments or concerns about this Privacy Policy or the information practices of this site, should be addressed to:
    SpotOn Transact, LLC  300 California St, Fourth Floor  San Francisco, CA 94104  877-814-4102  Facsimile: 877-521-6288  Support@SpotOn.com
or directly to the Merchant to which such questions, comments or concerns relate. Please review this Privacy Policy carefully. If you do not agree with this Privacy Policy, your ultimate choice is not to use the Services. Remember, by registering with SpotOn or using any part of the Services (whether you registered for them or a Merchant registered you with SpotOn), you accept and agree to the privacy practices described in this Privacy Policy.
    Each Client has agree that this Privacy Policy is incorporated by reference with the Merchant Terms, which govern their relationship with SpotOn.  SpotOn is not responsible for assuring that Merchants comply with this Privacy Policy.
    You agree that that this Privacy Policy is incorporated by reference into the User Terms. By visiting a SpotOn Website, SpotOn Mobile or using any of the SpotOn Access Portals, or using the Services (whether you registered for them or a Merchant registered you with SpotOn), you accept that any dispute over privacy is subject to this Privacy Policy and the User Terms, including but not limited to limitations on liability, disclaimers of damages, releases, waivers and application of the law of the State of Illinois. If you have any concern about privacy issues, please contact SpotOn as directed above.
    SpotOn reserves the right to update this Privacy Policy at any time. If this Privacy Policy is materially changed, the revised policy, or a notice that the policy has been revised, will be posted on the applicable SpotOn Website and SpotOn Mobile. You are responsible for periodically reviewing the Privacy Policy, and should do so frequently, especially before you provide any  Personally Identifiable Information. When SpotOn changes this Privacy Policy, we will also revise the “last updated” date at the end of this Privacy Policy. If SpotOn updates this Privacy Policy, continued use of the Services (following the posting of the revised Privacy Policy) means that you accept and agree to the terms of the revised Privacy Policy. SpotOn recommends that you periodically review this Privacy Policy.
    In addition, the words “includes,” “including,” or any variation thereof as used in this Privacy Policy means “including, without limitation” and shall not be construed to limit any general statement that it follows to the specific or similar items or matters immediately following it.
    This Privacy Policy was last updated on December 16th, 2019.</p>
</div>
);
