import axios from 'axios';

const apiUri = process.env.NODE_ENV === 'production'
    ? (window as any)._env_.REACT_CAPTIVE_PORTAL_API
    : process.env.REACT_APP_CAPTIVE_PORTAL_API;

export const checkCustomerEmail = (email: string): Promise<any> => {
    return axios.post(
        `${apiUri}customer/check-email`,
        {
            email
        }
    ).then(res => {
        return Promise.resolve(res.data)
    }).catch(e => {
        return Promise.reject(e.response.data)
    })
};

export const getCustomerDeals = (customerId: string): Promise<any> => {
    return axios.get(
        `${apiUri}customer/${customerId}/deals`
    ).then(res => {
        return Promise.resolve(res.data)
    }).catch(e => {
        return Promise.reject(e.response.data)
    })
};

export const joinCustomerAndMerchant = (customerId: string, merchantId: string): Promise<any> => {
    return axios.post(
        `${apiUri}customer/join/merchant`,
        {
            customer_id: customerId,
            merchant_id: merchantId
        }
    ).then(res => {
        return Promise.resolve(res.data)
    }).catch(e => {
        return Promise.reject(e.response.data)
    })
};

export const createCustomer = (customerEmail: string): Promise<any> => {
    return axios.post(
        `${apiUri}customer/create`,
        {
            email: customerEmail
        }
    ).then(res => {
        return Promise.resolve(res.data)
    }).catch(e => {
        return Promise.reject(e.response.data)
    })
};
