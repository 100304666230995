import React, { FC, useEffect, useState } from 'react';
import styles from './ConnectionSuccess.module.scss';
import { useLocation } from 'react-router';
import { Text, EmblemLogo, Button } from 'spoton-lib';
import { TCommonParams } from 'types/common.types';
import RouterHandler from 'utils/routerHandler/routerHandler';

export const ConnectionSuccess: FC = () => {
    const location = useLocation<TCommonParams>();
    const [outputError, setOutputError] = useState<string>();
    const [routerError, setRouterError] = React.useState<string>('');
    const [routerHandler, setRouterHandler] = useState<RouterHandler | null>(null);


    useEffect(() => {
        const handler = new RouterHandler(location.state);
        try {
            handler.validateParams();
        } catch (e:any) {
            setRouterError(e.message);
        }
        setRouterHandler(handler);
    }, [location.state]);


    const onGoToInternet = async () => {
        if(!routerHandler) return;
        try {
            await routerHandler.authenticate();
        } catch (e: any) {
            setOutputError(e.message);
        }
    };

    const hasMerchantLogo = location.state && location.state.merchantLogo && location.state.merchantLogo.length > 0;

    if (routerError.length > 0) {
        return (
            <div className={styles.Container}>
                <div className={styles.Error}>{outputError}</div>
            </div>
        )
    }

    return (
        <div className={styles.Container}>
            {
                hasMerchantLogo ? (
                    <img
                        className={styles.MerchantLogo}
                        src={location.state.merchantLogo}
                        alt="merchant-logo"
                    />
                ): (
                    <EmblemLogo />
                )
            }

            <Text className={styles.Title}>Connection Successful!</Text>
            <div className={styles.SuccessImg} />
            <Button className={styles.GoToInternetButton} onClick={onGoToInternet}>
                Go to Browser
            </Button>
            {
                outputError && outputError.length > 0 &&  
                <div className={styles.Error}>{outputError}</div>
            }
        </div>
    );
};
